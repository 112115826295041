import * as UI from '@/ui'

export type HeaderButton = {
  label: string
  slug: string
  path: string
}

// type HeaderSearchButtonProps = {
//   label: string
//   placeholder: string
//   action: () => void
// }

export type LearnLayoutHeaderProps = {
  headerData: {
    title: string
    description: string[]
    buttons?: HeaderButton[]
    //   searchButton?: HeaderSearchButtonProps
  }
}

export const LearnLayoutHeader = ({ headerData }: LearnLayoutHeaderProps) => {
  const { title, description, buttons } = headerData

  return (
    <div className="w-full h-full bg-violet-50 border-b-[3px] pt-8 pb-12">
      <div className="max-w-7xl mx-auto px-4 md:px-6">
        <UI.Block gap="xl">
          <UI.Heading color="midBlue" size={{ default: 'medium', md: 'large' }} weight="regular">
            <h1>{title}</h1>
          </UI.Heading>

          <UI.Block gap="small">
            {description.map((paragraph) => (
              <UI.Paragraph
                color="midBlue"
                size={{ default: 'medium', md: 'large' }}
                className="font-medium "
                key={paragraph}
              >
                {paragraph}
              </UI.Paragraph>
            ))}
          </UI.Block>

          {buttons && (
            <div className="max-w-sm md:max-w-5xl mx-auto gap-5 flex flex-col md:items-center md:flex-row md:justify-between">
              {buttons.map((button) => (
                <UI.Link
                  key={button.label}
                  type="button"
                  color="pink"
                  size="full"
                  to={['blogPost', { path: button.path }]}
                >
                  {button.label}
                </UI.Link>
              ))}
            </div>
          )}
        </UI.Block>

        {/* <div className="mx-auto self-center">SearchButton "w-3xl"</div> */}
      </div>
    </div>
  )
}

export default LearnLayoutHeader
