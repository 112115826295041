import { gql } from '@apollo/client'
import { NextSeo } from 'next-seo'
import { client } from '../../services/apollo-client'
import CategoriesMarkdownData from '../../components/learn/categories.json'
import CircleBlur from '../../public/images/circleBlur.svg?svgr'
import CategoryList, { CategoryProps } from '../../components/learn/category-list'
import BlogWhatsNew from '../../components/learn/blog-whats-new'
import TrendingArticlesList from '../../components/learn/trending-articles-list'
import AllArticles from '../../components/learn/all-articles'
import { resolveArticlesBySlugs } from '../../helpers/blogs'
import LearnLayoutHeader from '../../components/layout/header'
import { BlogCardFragment, BlogCardFragmentDoc, LearnHubPageDataQuery, LearnHubPageDataQueryVariables } from '@/gql'
import * as UI from '@/ui'

export type LearnHubArticle = BlogCardFragment & { summary: string }

type LearnHubProps = {
  articles: {
    all: LearnHubArticle[]
    trending: BlogCardFragment[]
  }
  categories: CategoryProps[]
}

export const LearnHub = ({ articles, categories }: LearnHubProps) => {
  return (
    <>
      <NextSeo
        title="Learn Hub"
        description="Become the expert in your own health with health and wellbeing articles and guides written by the doctors and scientists at Selph."
      />

      <UI.Block gap="large" className="relative mb-28 lg:mb-32">
        <CircleBlur className="absolute top-0 left-0 -translate-x-1/3 translate-y-1/4 w-[800px] h-[800px] md:w-[1500px] md:h-[1500px]" />

        <CircleBlur className="absolute top-1/2 right-0 translate-x-1/3 w-[800px] h-[800px] md:w-[1500px] md:h-[1500px]" />

        <UI.Block gap={{ default: 'large', md: 'xxl' }} className="relative max-w-7xl mx-auto">
          <UI.Block gap="xl">
            <UI.Heading
              color="midBlue"
              size={{ default: 'small', md: 'medium' }}
              weight="regular"
              className="text-center"
            >
              <h2>Health Topics</h2>
            </UI.Heading>

            <CategoryList categories={categories} />
          </UI.Block>

          <UI.Divider color="pink" />

          <UI.Block gap="xl" className="max-w-5xl mx-auto">
            <UI.Heading
              color="midBlue"
              size={{ default: 'small', md: 'medium' }}
              weight="regular"
              className="text-center"
            >
              <h2>What&apos;s New</h2>
            </UI.Heading>

            <BlogWhatsNew blog={articles.all[0]} />
          </UI.Block>

          <UI.Divider color="pink" />

          <UI.Block gap="xl">
            <UI.Heading
              color="midBlue"
              size={{ default: 'small', md: 'medium' }}
              weight="regular"
              className="text-center"
            >
              <h2>Trending Articles</h2>
            </UI.Heading>
            <TrendingArticlesList articles={articles.trending} />
          </UI.Block>

          <UI.Divider color="pink" />

          <UI.Block gap="xl">
            <UI.Heading
              color="midBlue"
              size={{ default: 'small', md: 'medium' }}
              weight="regular"
              className="text-center"
            >
              <h2>All Articles</h2>
            </UI.Heading>

            <AllArticles articles={articles.all} />
          </UI.Block>
        </UI.Block>
      </UI.Block>
    </>
  )
}

LearnHub.layoutProps = () => {
  const headerData = {
    title: 'Learn Hub',
    description: [
      "If you want to become an expert in your own health, you'll need answers to some important questions. You'll find those answers right here.",
      'Our medical experts review the evidence and dissect the science to separate fad from fiction and give you - in plain English - actionable insights you can trust.',
    ],
    // searchButton: {
    //   label: 'Search',
    //   placeholder: 'Search the Learn Hub',
    //   action: () => {
    //     alert('Searching articles')
    //   },
    // },
  }

  return {
    header: <LearnLayoutHeader headerData={headerData} />,
  }
}

export default LearnHub

const trendingSlugs = [
  '7-evidenced-based-actions-you-can-take-to-prevent-bowel-cancer-or-pick-it-up-earlier',
  'when-should-you-start-screening-for-bowel-cancer',
  'what-is-hba1c-and-whats-the-hba1c-normal-range',
]

export const getStaticProps = async () => {
  const response = await client.query<LearnHubPageDataQuery, LearnHubPageDataQueryVariables>({
    query: query,
    variables: {
      trendingSlugs,
    },
  })

  const trending = resolveArticlesBySlugs({
    slugs: trendingSlugs,
    articles: response.data.trendingArticles,
  })

  const categories = Object.values(CategoriesMarkdownData) as CategoryProps[]

  return {
    props: {
      articles: { all: response.data.allArticles, trending },
      categories,
    },
  }
}

export const hoverStyles =
  'overflow-clip shadow-[1.5px_1.5px_0_1.5px_#0D4780] transform transition-transform duration-200 ease-in-out hover:-translate-y-4 hover:shadow-[1.5px_1.5px_0_1.5px_#E22E89]'

const query = gql`
  query LearnHubPageData($trendingSlugs: [String!]!) {
    allArticles {
      __typename
      ...BlogCard
      summary
    }

    trendingArticles: articlesBySlug(slugs: $trendingSlugs) {
      __typename
      ...BlogCard
    }
  }

  ${BlogCardFragmentDoc}
`
