import BlogCard from './blog-card'
import { BlogCardFragment } from '@/gql'

export const TrendingArticlesList = ({ articles }: { articles: BlogCardFragment[] }) => {
  return (
    <div className="flex flex-wrap gap-y-10 gap-x-5 mx-auto justify-around">
      {articles.map((article) => (
        <BlogCard blog={article} key={article.title} showBadge />
      ))}
    </div>
  )
}

export default TrendingArticlesList
